.ProductCreateForm {
  position: relative;
  margin-top: 20px;
  width: 100%;
  h1 {
    margin: 0;
    text-align: center;
    padding: 20px 0;
    text-decoration: underline;
  }
}

.WarningWrapper {
  margin-bottom: 15px;
}

.Form {
  div[data-type="Resizer"] {
    display: none;
  }
  border-top: 1px solid #efefefef;
  border-bottom: 1px solid #efefefef;
}

.ProductSection {
  position: relative;
  padding-bottom: 50px;
  border-right: 1px solid #efefefef;

  .FormControlStyleWithSelect {
    display: block !important;
    margin: 20px auto !important;
    width: 90%;
    .ReactSelect {
      display: inline-block;
      width: 40%;
      margin-right: 3%;
    }
    .StyleInput {
      display: inline-block;
      width: 15%;
      div:first-child {
        width: 100%;
      }
    }
    .DefinedPart {
      display: inline-block;
      width: 37%;
      div:first-child {
        width: 100%;
      }
    }
  }
  .FormControlSelect {
    display: block !important;
    margin: 20px auto !important;
    width: 90%;
  }
  .FormControlTextArea {
    display: block !important;
    margin: 20px auto !important;
    width: 90%;
    label,
    div {
      width: 100% !important;
    }
    textarea {
      text-transform: uppercase;
    }
  }

  .DropzoneContainer {
    padding: 5px 0;
    margin: 0 auto;
    width: 90%;

    .Dropzone {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dotted grey;
      border-radius: 3px;

      .DropzoneDelete {
        position: absolute;
        top: 7px;
        right: 7px;
        min-width: 0 !important;
        width: 20px !important;
        padding: 0;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.CombinationsSection {
  .AlertInfo {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.SubmitButton {
  background-color: darkgreen !important;
  position: absolute !important;
  top: 10px;
  right: 10px;
  min-width: 0 !important;
  width: 90px;
  height: 34px;
  color: white !important;
}

.DeleteButton {
  background-color: #c11014 !important;
  position: absolute !important;
  top: 10px;
  right: 10px;
  min-width: 0 !important;
  width: 75px;
  height: 28px;
  font-size: 11px !important;
  color: white !important;
}

.Label {
  margin: 0;
  padding-bottom: 5px;
  font-size: 13px;
}

.InputFeedback {
  margin-top: 3px;
  font-size: 15px;
  line-height: 20px;
  color: darkred;
}

.errorBorder {
  border: 1px solid darkred;
  border-radius: 4px;
}

.ProductSubmit {
  position: absolute !important;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0);
}

.RequireDot {
  color: darkred;
}

.ChangeProductOrderReactSelect {
  position: absolute;
  display: flex;
  align-items: center;
  left: 10px;
  top: 10px;
  width: 350px;

  > p {
    margin-right: 15px;
  }

  .ProductOrderSelect {
    width: 90px;
  }
}
