.InformationContainer {
  position: relative;
}

.CreateButton {
  position: absolute !important;
  top: 15px;
  right: 15px;
  a {
    color: white;
    text-decoration: none;
  }
}