.Auth {
    margin: 40px auto;
    width: 600px;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 10px;
}

.Spinner {
    width: 100px !important;
    height: 100px !important;
}

.FormControl {
    display: block !important;
    margin: 20px auto !important;
    width: 200px;
    label, div {
        width: 100% !important;
    }
}

.InputFeedback {
    font-size: 11px;
    line-height: 20px;
    color: darkred;
}