.CustomToolbar {
  .ShowHidden {
    margin-left: 15px;
    span {
      font-size: 12px !important;
    }
  }
}

td {
  height: 48px !important;
}

.Wrapper {
  display: flex;

  .InvoiceSummary {
    margin-left: 10%;
  }
}

.SearchWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  width: auto;
}

.ColumnsMultiSelectWrapper {
  display: flex;
  position: absolute;
  right: 10px;
  top: 50px;
  margin: 15px 0;
  button {
    margin: 0 5px;
  }
}

.exportToExcelWrapper {
  display: inline-flex;
  flex-direction: column;
  width: 10%;
  .checkboxWrapper {
    margin-left: 24px;
  }
}

.WrapperModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ModalInput {
    padding: 10px;
  }

  .ModalButton {
    cursor: pointer;
  }

  .ModalQuantity {
    position: absolute;
  }

  .ModalInput,
  .ModalButton {
    margin: 5px 0;
  }
}

.FormControlStyleWithSelect {
  display: flex !important;
  margin: 10px auto 20px auto !important;
  width: 500px;
  padding: 20% 0;
  .ReactSelect {
    display: inline-block;
    width: 25%;
    margin-right: 5px;
  }
  .DesignerCode {
    display: inline-block;
    width: 10%;
    margin-right: 5px;
  }
  .ReactSelectContact {
    display: inline-block;
    width: 25%;
    margin-right: 5px;
  }
  .StyleInput {
    display: inline-block;
    width: 10%;
  }
}