.ToolbarLinkRow {
  display: initial;
  margin-left: 23px;
}

.FormInfo {
  margin: 0 auto;
  text-align: center;
  width: 80%;
}

.SearchWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  width: auto;
}

.FormControl {
  display: block !important;
  margin: 40px auto !important;
  width: 300px;
  label, div {
    width: 100% !important;
  }
  input {
    text-transform: uppercase;
  }
}

.FormControlSelect {
  display: block !important;
  margin: 20px auto !important;
  width: 300px;
}

.FormControlMulti {
  display: block !important;
  margin: 20px auto !important;
  width: 300px;
}

.InputFeedback {
  font-size: 13px;
  line-height: 20px;
  color: darkred;
}

.DropzoneContainer {
  padding: 20px;
}

.CustomToolbar {
  .ShowHidden {
    margin-left: 15px;
    margin-top: 10px;
    span {
      font-size: 12px !important;
    }
  }
}

.Form {
  div[data-type="Resizer"] {
    display: none;
  }
  position: relative !important;
  margin: 40px 0;
  border: 1px solid #efefefef;
}

.ProductSubmit {
  position: absolute !important;
  top: 0;
  right: 10px;
}

.CombinationsTable {
  padding: 40px 80px;
}

.Resizer {
  display: none;
}

.Label {
  margin: 0;
  padding-bottom: 5px;
  font-size: 10px
}

.FormControlStyleWithSelect {
  display: block !important;
  margin: 50px auto 20px auto !important;
  width: 300px;
  padding: 20% 0;
  .ReactSelect {
    display: inline-block;
    width: 35%;
    margin-right: 5px;
  }
  .DesignerCode {
    display: inline-block;
    width: 10%;
    margin-right: 5px;
  }
  .ReactSelectContact {
    display: inline-block;
    width: 35%;
    margin-right: 5px;
  }
  .StyleInput {
    display: inline-block;
    width: 10%;
  }
}

.ColumnsMultiSelectWrapper {
  display: flex;
  position: absolute;
  right: 10px;
  top: 50px;
  margin: 15px 0;
  button {
    margin: 0 5px;
  }
}

.exportToExcelWrapper {
  display: inline-flex;
  flex-direction: column;
  width: 10%;
  .checkboxWrapper {
    margin-left: 24px;
  }
}

