html {
  min-height: 100%;
  overflow-y: scroll;
}

body {
  padding-right: 0 !important;
  min-height: -webkit-fill-available;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Scrollbar custom */

body::-webkit-scrollbar {
  position: absolute;
  width: 5px;
  height: 10px;
}

body::-webkit-scrollbar-track {
  background-color: white;
  opacity: 0.6;
}

body::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 20px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);

  justify-content: center;
  align-items: center;

  overflow: auto;
  display: flex;
}

.overlay-browser-info {
  background-color: rgba(255, 255, 255, 0.6);
}

.overlay-browser-info .browser-info-error {
  background-color: #fff;
  width: 70%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-browser-info .browser-info-error .text-info {
  font-size: 1.5rem;
}

/*UPPERCASE DLA SEARCH INPUTÓW W CAŁYM PROJEKCIE*/
input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd {
  text-transform: uppercase;
}

input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd::placeholder {
  text-transform: none;
}

input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputTypeSearch.MuiInput-inputTypeSearch {
  text-transform: uppercase;
}

.detailPanel {
  padding: 20px;
  background-color: #adc6ff;
}

.detailPanelOrders {
  padding: 1px 30px 30px 30px;
  /*margin-top: -10px;*/
  background-color: #efefefef;
}

/* Na potrzeby full screen drawera z ikonami nawigacji */

:root {
  --test: lightpink;
  --zielony: #e2f0d9;
  --niebieski: #deebf7;
  --szary: #e0e0e0;
  --ciemno-szary: #5e5b5b;
}

p.marked {
  display: inline-flex;
  font-weight: bold;
  padding: 3px 5px;
  background-color: #ffd31d !important;
}

/* Products */
/* Podpisane przez szefa */
.rowAccepted td {
  font-weight: bold;
}

.rowCancelled td {
  background-color: var(--szary);
}

/*Dla products/orders/bookings które w accepted mają słowo cancelled */
.rowAcceptedCancelled td {
  color: #c00000 !important;
}

/*Dla products/bookings które w comments mają słowo cancelled */
.rowCommentCancelled td {
  color: #cc0000 !important;
}

/*INVOICES*/
.rowBanked td,
.rowBanked th {
  background-color: #dbff93 !important;
}

/* Booking JESLI WYSLANE POWYZEJ 105% */
.rowValidOverQuantity .amountPercentage {
  color: red !important;
}

.rowValidOverQuantity .quantity {
  color: red !important;
}

.rowValidOverQuantity quantityInvoice {
  color: red !important;
}

/*Booking klon*/
.rowClone td {
  color: var(--ciemno-szary) !important;
  font-style: italic !important;
}

/* Booking/Product gdy ma rabat*/
.rowDiscounted .pricePerOrder {
  color: #c00000 !important;
}

/* Booking zrobiony */

.rowBooked td {
  background-color: var(--niebieski);
}

.rowForwarderConfirmed td {
  background-color: var(--zielony);
}

/* Product deleted */
.rowProductCanceled td {
  color: red !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.uppercase input {
  text-transform: uppercase !important;
}

.customRow .MuiTableCell-root {
  padding: 0 0 0 10px !important;
  line-height: 1.2 !important;
}

.rowValidQuantity td,
.rowValidValue td,
.rowValidated td,
.rowValidated th {
  background-color: var(--szary) !important;
}

.rowContainerSupplied td {
  background-color: #bfbfbf !important;
}

.rowProductM td,
.rowContainerProductM td {
  color: #891fa6 !important;
}

.rowLC-D td {
  background-color: #f7ddbe !important;
}

.rowLC-P td {
  background-color: #f7bb77 !important;
}

.rowLC-B td {
  background-color: #f7992d !important;
}

.rowLC-O td {
  background-color: #c2f0c2 !important;
}

.rowLC-Z td {
  background-color: lightgrey !important;
}

/* Porty */
.rowContainer-SHA td {
  background-color: #ffffe6;
}

.rowContainer-TIA td {
  background-color: #66ffff;
}

.rowContainer-YTN td {
  background-color: #00b050;
}

.rowContainer-XMN td {
  background-color: #e6ccff;
}

.rowContainer-CTG td {
  background-color: #ffc000;
}

.rowContainer-KARACHI td {
  background-color: #bf8f00;
}

.rowContainer-QINGDAO td {
  background-color: #00b0f0;
}

.rowContainer-NSHV td {
  background-color: #ffccff;
}

.rowContainer-TUT td {
  background-color: #ff99ff;
}

.rowContainer-YAN {
  background-color: #808000;
}

.rowContainer-NINGBO {
  background-color: #ccffcc;
}

/* User Logs */
.rowUserLogsOk td {
  color: darkgreen !important;
}

.rowUserLogsFail td {
  color: darkred !important;
}

.columnTitleNarrow {
  transform: rotate(-90deg);
  padding: 0;
}

/* Sync Products */
.rowContainer-fullySynced td {
  background-color: #d6e9c3;
}

/* Forecasts */
.rowForecasts-toCheck td {
  background-color: #ffc000 !important;
}
