.ButtonsContainer {
  margin: 40px 0;
  text-align: center;
  button {
    margin-right: 10px;
  }
  button:last-child {
    margin-right: 0;
  }
  // Definicje buttonów
  .SaveProducts {
    background-color: darkgreen !important;
    color: white !important;
    display: none;
  }
}

.Blockquote {
  position: relative;
  margin: 20px 190px 20px 0;
  padding: 4px 24px;
  border-left: 5px solid #ffe564;
  background-color: rgba(255,229,100,0.2);
  p {
    margin: 16px 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    word-break: break-word;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }
  .RestoreButton {
    background-color: lightgrey !important;
    position: absolute !important;
    top: 15px;
    right: 10px;
    min-width: 0 !important;
    width: 150px;
    height: 28px;
    font-size: 11px !important;
    color: white !important;
  }
}