.filtersWrapper {
  border: 1px solid grey;
  padding: 15px;
  display: inline-flex;
  align-items: center;
  margin-left: 24px;
  position: relative;

  .title {
    position: absolute;
    top: -35px;
    left: 45%;
  }


  .columnsSelect {
    margin-left: 5px;
    margin-right: 15px;
    width: 200px;
  }

  .columnsSelectMulti {
    margin-left: 5px;
    margin-right: 15px;
    min-width: 200px;
    width: auto;
  }

  .conditionalSelect {
    margin-left: 5px;
    width: 150px;
  }

  .sendButton {
    margin-left: 15px;
  }
}