.Input {
    display: inline-block;
    width: 30%;
    padding: 10px;
    box-sizing: border-box;
}

.SelectElement {
    position: absolute !important;
    top: 10px;
    left: 0;
    width: 100%;
    border-color: #ccc;
    background-color: white;
    padding: 0;
}

.InputElement {
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    font-size: 12px;
    padding: 3px 5px;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.InputElement:focus {
    outline: none;
    background-color: #eee;
}

.Invalid {
    border: 1px solid red;
    background-color: #fae0d4;
}

.ValidationError {
    color: red;
    margin: -5px 0;
    font-size: 10px;
    position: absolute;
}

.WithoutPadding {
    label {
        transform: translate(14px, 12px) scale(1);
    }
    input {
        padding: 10px;
    }
}