.Form {
  position: relative;
  .Table {
    width: 100% !important;
  }
}

.LogoCell {
  width: 20%; // Dla całej kolumny
  text-align: center !important;
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.CollectionCell {
  text-align: center !important;
  border-right: 1px solid rgba(224, 224, 224, 1);
}

.Shipment {
  border-left: 1px solid rgba(224, 224, 224, 1);
  .Title {
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }
  .Content {
    width: 100%;
    .Input {
      margin: 20px auto;
    }
    .InputFeedback {
      text-align: center;
    }
  }
}

.ButtonsContainer {
  position: fixed !important;
  top: 110px;
  right: 35px;
  padding: 7px;
  border-radius: 4px;
  z-index: 1;
  background-color: #a6a6a6;
  .SubmitButton, .PdfButton {
    width: 30px;
    min-width: 0 !important;
    height: 30px;
    color: white !important;
  }
  .SubmitButton {
    width: 110px;
    font-size: 0.8em;
  }
  .PdfButton {
    background-color: #31639c !important;
    margin-right: 5px;
  }
  .SubmitButton {
    background-color: darkgreen !important;
  }
}


.RegularCell {
  width: 100% !important;
  display: flex !important;
  align-items: center;
  .Title {
    width: 30%;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .Content {
    width: 70%;
    display: block;
    .ReactSelect {
      display: inline-block;
      width: 25%;
      margin-right: 2%;
    }
    .DesignerCode {
      display: inline-block;
      width: 5%;
      margin-right: 2%;
    }
    .ReactSelectContact {
      display: inline-block;
      width: 25%;
      margin-right: 2%;
    }
    .StyleInput {
      display: inline-block;
      width: 15%;
    }
    .Payment {
      display: inline-block;
      width: 30%;
      margin-right: 5%;
    }
    .MultiInputFeedback {
      margin-left: 1%;
    }
  }
}

.InputFeedback {
  font-size: 13px;
  line-height: 20px;
  color: darkred;
}

.PDFButton {
  position: absolute;
  left: 0;
}

.PDFTitle {
  position: relative;
  text-align: center;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    margin: 0 auto;
    padding: 10px 0
  }
}