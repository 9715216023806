.App {
  position: relative;
  min-height: -webkit-fill-available;
  h3 {
    margin: 0;
  }
  .AppHeader {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
}