.Form {
  position: relative;
  border-bottom: 1px solid #efefefef;
  padding: 20px;
  h2 {
    margin: 0;
    text-align: center;
    padding-bottom: 20px;
    text-decoration: underline;
  }
  div[data-type="Resizer"] {
    display: none;
  }
  .FormControl {
    input {
      text-transform: uppercase;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .ButtonsContainer {
    text-align: center;
    .SubmitButton {
      background-color: darkgreen !important;
      color: white !important;
    }
    .DeleteButton {
      background-color: #c11014 !important;
      color: white !important;
    }
    .FormControl {
      margin-right: 20px;
    }
    .FormControl:last-child {
      margin-right: 0;
    }
  }
}

.Form:last-child {
  border-bottom: none;
}

.AddColourSection {
  

  .FormControlSelect {
    display: block !important;
    margin: 20px auto !important;
    width: 90%;
  }
  .FormControl {
    display: block !important;
    margin: 20px auto !important;
    width: 90%;
    label,
    div {
      width: 100% !important;
    }
    textarea {
      text-transform: uppercase;
    }
  }

  .SwatchUpperWrapper {
    margin: 20px auto !important;
    width: 90%;
  }

  .SwatchWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .DropzoneContainer {
    padding: 5px 0;
    margin: 0 auto;
    width: 50%;

    .Dropzone {
      position: relative;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dotted grey;
      border-radius: 3px;
      .DropzoneDelete {
        position: absolute;
        top: 7px;
        right: 7px;
        min-width: 0 !important;
        width: 20px !important;
        padding: 0;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .SwatchColor {
    width: 50%;
  }

  .Swatch {
    width: 150px;
    height: 150px;
    padding: 5px;
    margin: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.7);
    display: inline-block;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: gray;
      font-size: 11px;
    }
  }

  .Color {
    width: 150px;
    height: 150px;
    border-radius: 2px;
    position: absolute;
    top: 0;
  }

  .Popover {
    position: absolute;
    z-index: 2;
  }

  .Cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .FormControlTextArea {
    display: block !important;
    margin: 20px auto !important;
    width: 90%;
    label,
    div {
      width: 100% !important;
    }
    textarea {
      text-transform: uppercase;
    }
  }
}

.SystemInformation {
  .FormControlSelect {
    display: block !important;
    margin: 20px auto !important;
    width: 90%;
  }
}

h3 {
  margin: 0;
  text-align: center;
  padding: 20px 0;
  text-decoration: underline;
}

.SizesTable {
}

.Cell {
  border-top: 1px solid #efefefef;
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.Label {
  margin: 0;
  padding-bottom: 5px;
  font-size: 13px;
}

.InputFeedback {
  margin-top: 3px;
  font-size: 15px;
  line-height: 20px;
  color: darkred;
}

.errorBorder {
  border: 1px solid darkred;
  border-radius: 4px;
}

.RequireDot {
  color: darkred;
}
