.Footer {
    height: 10vh;
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 2rem;
    background-color: #efefef;
    text-align: center;
    p {
        color: grey;
    }
}